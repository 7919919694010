@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Oswald:wght@200..700&family=Playwrite+AR:wght@100..400&display=swap');

:root {
  --logo-fill-color: black;
  --logo-stroke-color: white;
}

[data-theme='dark'] {
  --logo-fill-color: white;
  --logo-stroke-color: black;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



#quoteBTN{
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 3.5rem;
  border-radius: 4rem;
  text-align: center;
  height: auto;
  width: auto;
  padding: 0.8rem;
}

.fontDance{
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
  letter-spacing: 0.05em;
  text-decoration: none;
  color: inherit;
  transition: ease 0.6s;
}
.fontDance:hover{
  color: hsl(189, 55%, 49%);
  transition: ease 0.6s;
}
#quoteBTN:hover{
  cursor: pointer;
  transition: background-color 0.3s;

}
.red-text{
  color: red !important;
}
.strong{
  font-weight: 900;
}
.letter{
  color: hsl(210, 98%, 42%);
}

.appointH2{
  font-family: "oswald", cursive;
  font-size: 4rem;
  letter-spacing: 0.05em;
  color: inherit;
  transition: ease 0.6s;
  margin-bottom: 0.12rem;
}
.appointH2:hover{
  color: hsl(189, 55%, 49%);
  transition: ease 0.6s;
  cursor: pointer;
}

.appointP{
  font-family: "Dancing Script", serif !important;
  font-size: 2.5rem;
  margin-top: -2px;
}

/* #appointMeet{ */
  /* font-family: "oswald";
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 2.5rem;
  background-color: hsl(210, 100%, 35%);
  color: white;
  border-radius: 4rem;
  text-align: center;
  height: auto;
  width: auto;
  padding: 0.8rem;
  padding: 0.2rem; */
/* } */

/* scrollbar.css */

/* Customize scrollbar for WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  /* height: px; Height of the scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
  border-radius: 8px; /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
  background: transparent; /* Color of the scrollbar thumb */
  border-radius: 8px; /* Rounded corners for the thumb */
}

/* Optional: Change color on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent /* Darker shade on hover */
}

/* Customize scrollbar for Firefox */
.scrollbar-custom {
  scrollbar-width: thin; /* Make scrollbar thinner */
  /* scrollbar-color: var(--thumb-color) var(--track-color); Thumb color and track color */
}

/* Apply custom scrollbar styles globally */
body {
  margin: 0;
  font-family: 'Arial', sans-serif; /* Set your desired font */
  overflow-y: scroll; /* Ensure vertical scrolling is enabled */
}
